@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
header {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
}

header .header-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  min-height: 10rem;
  position: relative;
  z-index: 500;
}

header .header-content .header-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

header .header-content .header-center a {
  font-size: 1.5rem;
  margin: 0 3.5rem;
  color: #323232;
  text-decoration: none;
  -webkit-transition: 0.25s;
  transition: 0.25s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

header .header-content .header-center a * {
  font-family: Nebula-Regular, "Open Sans", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
}

header .header-content .header-center a span {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  display: block;
}

header .header-content .header-center a:hover span {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}

header .header-content .header-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

header .header-content .header-right .menu-icon {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  display: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

header .header-content .header-right .menu-icon span {
  background-color: rgba(0, 0, 0, 0.85);
}

header .header-content .header-right .menu-icon span:nth-child(1) {
  width: 3.25rem;
  height: 1px;
  display: block;
}

header .header-content .header-right .menu-icon span:nth-child(2) {
  width: 1.75rem;
  height: 1px;
  display: block;
  margin: 0.5rem 0;
}

header .header-content .header-right .menu-icon span:nth-child(3) {
  width: 2.25rem;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.85);
  display: block;
}

header .header-content .header-right a {
  margin: 0 0 0 1rem;
  width: 3.5rem;
  height: 3.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 0.65rem;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  color: #000;
}

header .header-content .header-right a:hover {
  background-color: #f5f6f7;
  -webkit-transform: translate(0, -0.25rem);
  transform: translate(0, -0.25rem);
}

header .header-content .header-right a i {
  font-size: 2rem;
  -o-object-fit: contain;
  object-fit: contain;
}

header .header-mob {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: black;
  z-index: 450;
  height: 0;
  overflow: hidden;
}

header .header-mob .header-mob-content {
  width: 100%;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

header .header-mob .header-mob-content a {
  margin: 2.5rem 0;
  font-size: 3.5rem;
  color: rgba(255, 255, 255, 0.85);
  text-decoration: underline;
}

header .header-mob .header-mob-content a:active {
  -webkit-transform: scale(0.85);
  transform: scale(0.85);
}
header .header-content .header-right .menu-icon span.white-bg {
  background-color: white;
}
.hh-d {
  display: block;
}

.hh-m {
  display: none;
}

.hero-section {
  width: 100%;
  position: relative;
}

.hero-section .hero-line-1 {
  width: 100%;
  /* width: 70%; */
  height: 1px;
  background-color: #323232;
  position: absolute;
  bottom: 0;
  left: 0;
}

.hero-section .suifyan-box {
  width: 35%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.hero-section .sufiyan {
  width: 35vw;
  height: 100vh;
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-clip-path: polygon(0 0, 60% 0, 100% 100%, 0% 100%);
  clip-path: polygon(0 0, 60% 0, 100% 100%, 0% 100%);
}

.hero-section .hero-heading-circle {
  position: absolute;
  bottom: 3.5rem;
  left: 3.5rem;
  z-index: 1000;
  color: #fff;
}

.hero-section .hero-section-content {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 5rem 0;
  position: relative;
  z-index: 9;
}

.hero-section .hero-section-content h1 {
  font-size: 10rem;
  position: relative;
}

.hero-section .hero-section-content h1 * {
  font-family: Nebula-Regular, "Open Sans", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
}

.hero-section .hero-section-content h1 .hh-h1-box {
  width: 12.5rem;
  height: 3rem;
  position: absolute;
  top: 5rem;
  left: 7.5rem;
  z-index: 10;
}

.hero-section .hero-section-content h1 .hh-h1-box span {
  width: 12.5rem;
  height: 3rem;
  background-color: #323232;
  position: absolute;
  top: 0;
  left: 0;
}

.hero-section .hero-section-content h1 .hero-heading-line-1 {
  width: 45%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.35);
  position: absolute;
  top: 0;
  right: -10%;
}

.hero-section .hero-section-content h1 .hero-heading-line-2 {
  width: 25%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.35);
  position: absolute;
  bottom: 0;
  left: 10%;
}

.hero-section .hero-section-content h2 {
  font-size: 1.65rem;
  font-weight: 400;
  margin: 1rem 0 3.5rem 0;
  letter-spacing: 1.25rem;
  text-align: center;
}

.hero-section .hero-section-content > a {
  background-color: rgba(0, 0, 0, 0.85);
  padding: 1.25rem 3.5rem;
  font-size: 1.5rem;
  color: #fff;
}

.why-section {
  width: 100%;
  padding: 7.5rem 0;
}

.why-section .why-section-content {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.why-section .why-section-content p {
  width: 65%;
  text-align: center;
  margin: 3.5rem 0;
  font-size: 1.5rem;
  line-height: 2.5rem;
}

.why-section .why-section-content a {
  font-size: 1.5rem;
  font-weight: 500;
  color: #000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.why-section .why-section-content a i {
  margin: 0 0 0 1.5rem;
  font-size: 1.65rem;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  display: block;
}

.why-section .why-section-content a:hover i {
  -webkit-transform: translate(0.75rem, 0);
  transform: translate(0.75rem, 0);
}

.packages-section {
  width: 100%;
  padding: 7.5rem 0;
}

.packages-section .packages-section-content {
  width: 100%;
  margin: 7.5rem 0 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.packages-section .packages-grid {
  width: 75%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  grid-gap: 2.5rem;
}

.packages-section .packages-grid .packages-grid-card {
  width: 100%;
  padding: 2rem;
  border-radius: 1rem;
  background-color: #f5f6f7;
  color: #000;
  cursor: pointer;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.packages-section .packages-grid .packages-grid-card .pgc-top {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.packages-section .packages-grid .packages-grid-card .pgc-top h4 {
  font-size: 2.5rem;
  font-weight: 700;
  font-style: italic;
}

.packages-section .packages-grid .packages-grid-card .pgc-top .logo {
  -webkit-transform: scale(0.65) rotate(-90deg);
  transform: scale(0.65) rotate(-90deg);
  margin: 0 0 0 1rem;
}

.packages-section .packages-grid .packages-grid-card .pgc-bottom {
  width: 100%;
  margin: 10rem 0 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.packages-section .packages-grid .packages-grid-card .pgc-bottom h5 {
  font-size: 2.25rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.packages-section .packages-grid .packages-grid-card .pgc-bottom h5 span {
  font-size: 1.25rem;
  font-weight: 400;
}

.packages-section .packages-grid .packages-grid-card .pgc-bottom p {
  color: #000;
  font-size: 1.5rem;
  font-weight: 500;
}

.packages-section .packages-grid .packages-grid-card .pgc-bottom p i {
  margin: 0 0 0 0.5rem;
}

.packages-section .packages-grid .packages-grid-card:hover {
  background-color: #323232;
  color: #fff;
  -webkit-transform: scale(1.035);
  transform: scale(1.035);
}

.packages-section .packages-grid .packages-grid-card:hover p {
  color: #fff;
}

.packages-section .packages-grid .packages-grid-card:hover .logo {
  -webkit-transform: scale(0.85) rotate(-90deg);
  transform: scale(0.85) rotate(-90deg);
}

.packages-section .packages-grid .packages-grid-card:hover .logo .logo-box {
  background-color: #fff;
}
.packages-section
  .packages-grid
  .packages-grid-card:hover
  .logo
  .logo-box::after {
  background-color: #fff;
}

.customers-section {
  width: 100%;
  padding: 7.5rem 0;
}

.customers-section .customers-section-content {
  width: 100%;
  margin: 7.5rem 0 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.customers-section .customers-section-content .customers-grid {
  width: 75%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  grid-gap: 2.5rem;
}

.customers-section
  .customers-section-content
  .customers-grid
  .customers-grid-card {
  width: 100%;
  padding: 3rem 2.2rem;
  border-radius: 1rem;
  /* background-color: #323232; */
  background-color: #f5f6f7;
  text-align: center;
  display: flex;
}
.customers-section
  .customers-section-content
  .customers-grid
  .customers-grid-card:hover {
  background-color: #ebecee;
}
.customers-section
  .customers-section-content
  .customers-grid
  .customers-grid-card:hover
  img {
  transform: scale(1.03);
}
.customers-section
  .customers-section-content
  .customers-grid
  .customers-grid-card
  img {
  width: 100%;
  /* max-height: 5rem; */
  -o-object-fit: contain;
  object-fit: contain;
  transition: 0.3s linear;
}

.testimonials-section {
  width: 100%;
  padding: 7.5rem 0;
}

.testimonials-section .testimonials-section-content {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 7.5rem 0 0 0;
  position: relative;
}

.testimonials-section .testimonials-section-content .testimonials-swiper-grid {
  width: 75%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

.testimonials-section .testimonials-section-content .testimonials-pagination {
  position: relative;
  top: unset;
  bottom: unset;
  left: unset;
  right: unset;
  -webkit-transform: unset !important;
  transform: unset !important;
  /* display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;  */
  margin: 2.5rem 0 0 0;
}

.testimonials-section
  .testimonials-section-content
  .testimonials-pagination
  .swiper-pagination-bullet {
  background-color: rgba(0, 0, 0, 0.15);
  opacity: 1;
  /* width: 6px;
        height: 6px; */
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.testimonials-section
  .testimonials-section-content
  .testimonials-pagination
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: rgba(0, 0, 0, 0.85);
  opacity: 1;
}

.testimonials-section
  .testimonials-section-content
  .testimonials-navigation-box {
  width: 3.5rem;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.testimonials-section .testimonials-section-content .testimoonial-left {
  margin: 0 3.5rem 0 0;
}

.testimonials-section .testimonials-section-content .testimoonial-right {
  margin: 0 0 0 3.5rem;
}

.testimonials-section .testimonials-section-content .testimoonial-left,
.testimonials-section .testimonials-section-content .testimoonial-right {
  min-width: 3.5rem;
  max-width: 3.5rem;
  min-height: 3.5rem;
  max-height: 3.5rem;
  background-color: #f5f6f7;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 1.5rem;
  color: #000;
  border-radius: 0.5rem;
  cursor: pointer;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.testimonials-section .testimonials-section-content .testimoonial-left:hover,
.testimonials-section .testimonials-section-content .testimoonial-right:hover {
  background-color: rgba(0, 0, 0, 0.85);
  color: #fff;
}

.testimonials-section .testimonials-section-content .testimonial-slide-card {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: #323232;
  color: #fff;
  padding: 2.5rem;
  border-radius: 1.5rem;
}

.testimonials-section .testimonials-section-content .testimonial-slide-card h3 {
  font-size: 2.5rem;
}

.testimonials-section .testimonials-section-content .testimonial-slide-card h4 {
  font-size: 1.35rem;
  font-weight: 400;
  opacity: 0.5;
  margin: 0.5rem 0 1.5rem 0;
}

.testimonials-section .testimonials-section-content .testimonial-slide-card p {
  font-size: 1.5rem;
  line-height: 2.5rem;
}

footer {
  margin: 15rem 0 2.5rem 0;
}

footer .footer-stabalizer {
  height: 0.25rem;
  width: 100%;
}

footer .footer-top p {
  width: 50%;
  font-size: 1.5rem;
  line-height: 2rem;
  margin: 2.5rem 0 0 0;
  color: rgba(0, 0, 0, 0.85);
  letter-spacing: 1.25rem;
}

footer .footer-center {
  width: 100%;
  margin: 3.5rem 0;
  background-color: #f5f6f7;
}

footer .footer-center .fc-content {
  padding: 5rem 0;
}

footer .footer-center .fc-content .fc-top {
  width: 100%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  grid-gap: 0;
  margin: 0 0 5rem 0;
  padding: 0 0 5rem 0;
  border-bottom: 1px solid #323232;
}

footer .footer-center .fc-content .fc-top a,
footer .footer-center .fc-content .fc-top h6 {
  font-size: 1.5rem;
  font-weight: 700;
  width: 100%;
  padding: 0.5rem 0;
  color: #000;
}

footer .footer-center .fc-content .fc-top a span,
footer .footer-center .fc-content .fc-top h6 span {
  font-weight: 500;
}

footer .footer-center .fc-content .fc-top :nth-child(2) {
  text-align: center;
  border-left: 1px solid #323232;
  border-right: 1px solid #323232;
}

footer .footer-center .fc-content .fc-top :nth-child(3) {
  text-align: right;
}

footer .footer-center .fc-content .fc-bottom {
  width: 100%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 2fr;
  grid-template-columns: 1fr 2fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  grid-gap: 0;
  position: relative;
}

footer .footer-center .fc-content .fc-bottom .fcb-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

footer .footer-center .fc-content .fc-bottom .fcb-left a {
  margin: 0 0 0 1rem;
  width: 3.5rem;
  height: 3.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 0.65rem;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  color: #000;
  cursor: pointer;
}

footer .footer-center .fc-content .fc-bottom .fcb-left a:hover {
  background-color: #fff;
  -webkit-transform: translate(0, -0.25rem);
  transform: translate(0, -0.25rem);
}

footer .footer-center .fc-content .fc-bottom .fcb-left a i {
  font-size: 2rem;
  -o-object-fit: contain;
  object-fit: contain;
  cursor: pointer;
}

footer .footer-center .fc-content .fc-bottom .fcb-right {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

footer .footer-center .fc-content .fc-bottom .fcb-right .back-to-top {
  min-width: 3.5rem;
  max-width: 3.5rem;
  min-height: 3.5rem;
  max-height: 3.5rem;
  background-color: #323232;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 1.5rem;
  color: #fff;
  border-radius: 0.5rem;
  cursor: pointer;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

footer .footer-center .fc-content .fc-bottom .fcb-right .back-to-top:hover {
  -webkit-transform: translate(0, -0.5rem);
  transform: translate(0, -0.5rem);
}

footer .footer-center .fc-content .fc-bottom .fcb-center {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

footer .footer-center .fc-content .fc-bottom .fcb-center a {
  font-size: 1.5rem;
  margin: 0 3.5rem;
  color: #323232;
  text-decoration: none;
  -webkit-transition: 0.25s;
  transition: 0.25s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

footer .footer-center .fc-content .fc-bottom .fcb-center a * {
  font-family: Nebula-Regular, "Open Sans", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
}

footer .footer-center .fc-content .fc-bottom .fcb-center a span {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  display: block;
}

footer .footer-center .fc-content .fc-bottom .fcb-center a:hover span {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}

footer .footer-bottom {
  width: 100%;
  margin: 0 0 2.5rem 0;
}

footer .footer-bottom .fb-content {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

footer .footer-bottom .fb-content h6 {
  font-size: 1.35rem;
  font-weight: 400;
  color: #323232;
}

.fixed-whts-btn {
  position: fixed;
  bottom: 19px;
  right: 8px;
  z-index: 999;
  font-size: 29px;
  color: #fff;
  text-decoration: none;
  width: 58px;
  height: 58px;
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid #fff;
  background-color: #25d366;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.269);
}
.fixed-upwork-btn {
  background-color: #14a800;
}
.fixed-whts-btn img {
  width: 100%;
}
.fixed-upwork-btn {
  bottom: 84px;
}
@media screen and (max-width: 1150px) {
  .why-section .why-section-content p {
    width: 75%;
  }
  .packages-section .packages-grid {
    width: 85%;
  }
  .customers-section .customers-section-content .customers-grid {
    width: 85%;
  }
  .testimonials-section
    .testimonials-section-content
    .testimonials-swiper-grid {
    width: 85%;
  }
  .hero-section .hero-section-content h1 {
    font-size: 3.5rem;
  }
  .hero-section .hero-section-content h2 {
    font-size: 1.5rem;
  }
  .hero-section .hero-heading-circle {
    left: 2.5rem;
    bottom: 2.5rem;
  }
}

@media screen and (max-width: 1150px) {
  .why-section .why-section-content p {
    width: 100%;
  }
  .packages-section .packages-grid {
    width: 100%;
  }
  .customers-section .customers-section-content .customers-grid {
    width: 100%;
  }
  .testimonials-section
    .testimonials-section-content
    .testimonials-swiper-grid {
    width: 100%;
  }
  footer .footer-top p {
    width: 75%;
  }
}

@media screen and (max-width: 850px) {
  .packages-section .packages-grid .packages-grid-card .pgc-top h4 {
    font-size: 2.25rem;
  }
  .customers-section
    .customers-section-content
    .customers-grid
    .customers-grid-card {
    padding: 2.5rem;
  }
  footer .footer-top p {
    width: 100%;
  }
  footer .footer-center .fc-content .fc-top {
    display: none;
  }
  footer .footer-center .fc-content {
    padding: 3.5rem 0;
  }

  footer .footer-bottom .fb-content h6 {
    width: 100%;
    text-align: center;
  }
  footer .footer-center .fc-content .fc-bottom .fcb-center {
    display: none;
  }
  footer .footer-center {
    margin: 0;
    background-color: unset;
  }

  .hero-section .suifyan-box {
    width: 40%;
  }
  .hero-section .sufiyan {
    width: 40vw;
  }
}

@media screen and (max-width: 750px) {
  .packages-section .packages-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  .customers-section .customers-section-content .customers-grid {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }
  header .header-content .header-right .menu-icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  /* header .header-content .header-right a,  */
  header .header-content .header-center {
    display: none;
  }
}

@media screen and (max-width: 550px) {
  .customers-section .customers-section-content .customers-grid {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.5rem;
  }
  .customers-section
    .customers-section-content
    .customers-grid
    .customers-grid-card {
    padding: 2.5rem;
    border-radius: 0.5rem;
  }
  .customers-section
    .customers-section-content
    .customers-grid
    .customers-grid-card
    img {
    height: 3.5rem;
  }
  .customers-section .customers-section-content,
  .packages-section .packages-section-content {
    margin: 3.5rem 0 0 0;
  }
  .packages-section .packages-grid {
    grid-gap: 1.5rem;
  }
  .packages-section .packages-grid .packages-grid-card {
    padding: 1.5rem;
  }
  .why-section .why-section-content p {
    margin: 2.5rem 0;
  }
  .why-section,
  .customers-section,
  .testimonials-section,
  .packages-section {
    padding: 5rem 0;
  }
  .testimonials-section .testimonials-section-content .testimoonial-left,
  .testimonials-section .testimonials-section-content .testimoonial-right {
    display: none;
  }
  footer {
    margin: 10rem 0 0 0;
  }
  header .header-content {
    min-height: 7.5rem;
  }
  .hero-section .suifyan-box {
    height: 100vh;
    width: 100%;
  }
  .hero-section .sufiyan {
    height: 100vh;
    width: 100%;
    -webkit-filter: brightness(0.5);
    filter: brightness(0.5);
    -webkit-clip-path: unset;
    clip-path: unset;
  }
  .hero-section .hero-section-content {
    min-height: 100vh;
    padding-top: 6.2rem;
  }
  .hero-section .hero-section-content h1 {
    /* color: rgba(255, 255, 255, 0.85); */
  }

  header .header-content .header-right a {
    /* display: none; */
  }
  header .header-content .header-right a i {
    font-size: 2rem;
  }
  .hero-section .hero-section-content h1 {
    font-size: 5.5rem;
  }
  .hero-section .hero-section-content h1 .hero-heading-line-1,
  .hero-section .hero-section-content h1 .hero-heading-line-2 {
    /* background-color: rgba(255, 255, 255, 0.65); */
  }
  .hero-section .hero-section-content h2 {
    /* color: rgba(255, 255, 255, 0.85); */
    margin: 1.5rem 0 5rem 0;
    letter-spacing: 1.15rem;
  }
  .hh-d {
    display: none;
  }
  .hh-m {
    display: block;
  }
  .hero-section .hero-section-content button {
    background-color: rgba(0, 0, 0, 0.95);
  }

  footer .footer-top p {
    margin: 1.5rem 0 0 0;
    letter-spacing: 1rem;
  }
  header .header-content .header-center {
    display: none;
  }
}

.hero-section header .header-content .header-right .menu-icon span {
  background-color: rgba(255, 255, 255, 0.85);
}

.close-mob-header {
  display: none;
}

.contact-hero-section {
  width: 100%;
  position: relative;
}

.contact-hero-section .chs-content {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.contact-hero-section .chs-grid-container {
  width: 100%;
  padding: 7.5rem 0;
  margin: 0 0 10rem 0;
  background-color: #f5f6f7;
}

.contact-hero-section .chs-grid-container .chs-grid {
  width: 85%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 0 auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  grid-gap: 5rem;
}

.contact-hero-section .chs-grid-container .chs-grid .chs-grid-card {
  width: 100%;
  background-color: #323232;
  padding: 3.5rem;
  color: #fff;
  border-radius: 1.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.contact-hero-section .chs-grid-container .chs-grid .chs-grid-card h4 {
  font-size: 2.25rem;
  text-align: center;
}

.contact-hero-section .chs-grid-container .chs-grid .chs-grid-card span {
  width: 5rem;
  height: 1px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #f5f6f7;
  margin: 0.5rem 0 2.5rem 0;
}

.contact-hero-section .chs-grid-container .chs-grid .chs-grid-card p,
.contact-hero-section .chs-grid-container .chs-grid .chs-grid-card a {
  font-size: 1.5rem;
  text-align: center;
  margin: 0 0 0.25rem 0;
  width: 100%;
  color: #fff;
}

.contact-hero-section .chs-grid-container .chs-grid .chs-grid-card p i,
.contact-hero-section .chs-grid-container .chs-grid .chs-grid-card a i {
  font-size: 1.5rem;
  margin: 0 0.5rem 0 0;
}

.whatsapp {
  width: 6.5rem;
  height: 6.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 3rem;
  color: #fff;
  background-color: #323232;
  border-radius: 1000px;
  position: fixed;
  bottom: 2.5rem;
  right: 2.5rem;
  cursor: pointer;
  border: 1px solid #fff;
}

.fiverr-upwork-section {
  width: 100%;
}

.fiverr-upwork-section .fus-content {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.fiverr-upwork-section .fus-content h3 {
  font-size: 2.5rem;
}

.fiverr-upwork-section .fus-content .fus-grid {
  width: 75%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  grid-gap: 5rem;
  margin: 7.5rem auto 0 auto;
}

.fiverr-upwork-section .fus-content .fus-grid .fus-grtid-card {
  width: 100%;
  background-color: #f5f6f7;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 7.5rem;
  border-radius: 1.5rem;
  cursor: pointer;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  position: relative;
  overflow: hidden;
}

.fiverr-upwork-section .fus-content .fus-grid .fus-grtid-card p {
  font-size: 1.25rem;
  color: #fff;
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 150%);
  transform: translate(-50%, 150%);
  z-index: 10;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.fiverr-upwork-section .fus-content .fus-grid .fus-grtid-card p i {
  margin: 0 0 0 0.5rem;
}

.fiverr-upwork-section .fus-content .fus-grid .fus-grtid-card * {
  -webkit-transition: 0.25s;
  transition: 0.25s;
}

.fiverr-upwork-section .fus-content .fus-grid .fus-grtid-card svg {
  height: 4em;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  -o-object-fit: contain;
  object-fit: contain;
}

.fiverr-upwork-section .fus-content .fus-grid .fus-grtid-card img {
  height: 4em;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  -o-object-fit: contain;
  object-fit: contain;
}

.fiverr-upwork-section .fus-content .fus-grid .fus-grtid-card:hover p {
  -webkit-transform: translate(-50%, -1.25rem);
  transform: translate(-50%, -1.25rem);
}

.fiverr-upwork-section .fus-content .fus-grid .fus-grtid-card:nth-child(1) {
  background-color: #404145;
}

.fiverr-upwork-section
  .fus-content
  .fus-grid
  .fus-grtid-card:nth-child(1)
  .f-l-c {
  fill: #fff !important;
}

.fiverr-upwork-section .fus-content .fus-grid .fus-grtid-card:nth-child(2) {
  background-color: #14a800;
}

.fiverr-upwork-section
  .fus-content
  .fus-grid
  .fus-grtid-card:nth-child(2)
  .u-l-c {
  fill: #fff !important;
}

@media screen and (max-width: 1200px) {
  .contact-hero-section .chs-grid-container .chs-grid,
  .fiverr-upwork-section .fus-content .fus-grid {
    width: 100%;
    grid-gap: 3.5rem;
  }
}

@media screen and (max-width: 1000px) {
  .contact-hero-section .chs-grid-container .chs-grid,
  .fiverr-upwork-section .fus-content .fus-grid {
    width: 100%;
    grid-gap: 2.5rem;
  }
  .contact-hero-section .chs-grid-container {
    padding: 3.5rem 0;
  }
}

@media screen and (max-width: 850px) {
  .contact-hero-section .chs-grid-container .chs-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    width: 50%;
  }
}

@media screen and (max-width: 650px) {
  .contact-hero-section .chs-grid-container .chs-grid {
    width: 100%;
  }
  .fiverr-upwork-section .fus-content .fus-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    margin: 3.5rem auto 0 auto;
  }
  .fiverr-upwork-section .fus-content .fus-grid .fus-grtid-card {
    padding: 6.5rem 0;
  }
  .fiverr-upwork-section .fus-content .fus-grid .fus-grtid-card svg {
    height: 3.5rem;
  }
  .whatsapp {
    width: 5rem;
    height: 5rem;
    font-size: 2.5rem;
    bottom: 1.5rem;
    right: 1.5rem;
  }
}

.portfolio-grid-section {
  width: 100%;
}

.portfolio-grid-section .portfolio-grid {
  width: 85%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  grid-gap: 5rem;
  margin: 5rem auto 0 auto;
}

.portfolio-grid-section .portfolio-grid .portfolio-grid-card {
  width: 100%;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.portfolio-grid-section .portfolio-grid .portfolio-grid-card img {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: top;
  object-position: top;
  -webkit-transition: 1s;
  transition: 1s;
  position: relative;
  z-index: 15;
}

.portfolio-grid-section .portfolio-grid .portfolio-grid-card div {
  width: 00%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0;
  z-index: 20;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: 0.65s;
  transition: 0.65s;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.85) !important;
}

.portfolio-grid-section .portfolio-grid .portfolio-grid-card h2 {
  font-size: 2.5rem;
  font-weight: 500;
  z-index: 10;
  color: #fff;
  opacity: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.portfolio-grid-section .portfolio-grid .portfolio-grid-card h2 i {
  font-size: 2rem;
  margin: 0 0 0 1.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.portfolio-grid-section .portfolio-grid .portfolio-grid-card:hover img {
  -webkit-filter: blur(20px);
  filter: blur(20px);
}

.portfolio-grid-section .portfolio-grid .portfolio-grid-card:hover div {
  width: 100%;
}

.portfolio-grid-section .portfolio-grid .portfolio-grid-card:hover div h2 {
  opacity: 1;
}

.project-section {
  padding: 15rem 0 0 0;
}

.project-section .project-section-content {
  width: 65%;
  margin: 0 auto;
}

.project-section .project-section-content p {
  font-size: 1.5rem;
  line-height: 2.5rem;
  margin: 5rem 0 0 0;
  color: #323232;
}

.project-section .project-section-content .project-heading {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.project-section .project-section-content .project-heading div h2 {
  font-size: 3.5rem;
  font-weight: 600;
}

.project-section .project-section-content .project-heading div h6 {
  font-size: 1.5rem;
  color: #949494;
  font-weight: 400;
}

.project-section .project-section-content .project-heading a {
  color: #323232;
  font-size: 2.75rem;
  text-transform: uppercase;
}

.project-section .project-section-content .project-heading a i {
  margin: 0 0 0 0.75rem;
}

.project-section .project-section-content .projects-pictures-grid {
  width: 100%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  grid-gap: 5rem;
  margin: 5rem 0 0 0;
}

.project-section .project-section-content .projects-pictures-grid img {
  width: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

@media screen and (max-width: 1000px) {
  .portfolio-grid-section .portfolio-grid {
    width: 100%;
    grid-gap: 2.5rem;
  }
  .project-section .project-section-content {
    width: 85%;
  }
}

@media screen and (max-width: 850px) {
  .portfolio-grid-section .portfolio-grid {
    width: 75%;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  .project-section .project-section-content {
    width: 100%;
  }
}

@media screen and (max-width: 750px) {
  .portfolio-grid-section .portfolio-grid {
    width: 100%;
    margin: 0;
  }
  .project-section .project-section-content .project-heading {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .project-section .project-section-content .project-heading a {
    margin: 2.5rem 0 0 0;
  }
  .project-section .project-section-content .project-heading div h2 {
    font-size: 2.75rem;
  }
  .project-section {
    padding: 10rem 0 0 0;
  }
}

.about-hero-section {
  width: 100%;
  position: relative;
  min-height: 100vh;
}

.about-hero-section .chs-heading-container {
  padding: 15rem 0 6.5rem 0;
}

.about-hero-section .about-distorted-section {
  width: 100%;
  position: relative;
}

.about-hero-section .about-distorted-section .ads-content {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 35rem 1fr;
  grid-template-columns: 1fr 35rem 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  grid-gap: 7.5rem;
}

.about-hero-section .about-distorted-section .ads-content .adsc-left-box {
  width: 100%;
  height: 100%;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.about-hero-section .about-distorted-section .ads-content .adsc-center-box {
  width: 100%;
  position: relative;
}

.about-hero-section
  .about-distorted-section
  .ads-content
  .adsc-center-box
  .about-img-line-1 {
  width: 50%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  bottom: -1rem;
  left: 0;
  z-index: 10;
}

.about-hero-section
  .about-distorted-section
  .ads-content
  .adsc-center-box
  .about-img-line-2 {
  width: 50%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: -1rem;
  right: 0;
  z-index: 10;
}

.about-hero-section .about-distorted-section .ads-content .about-img-box {
  width: 100%;
  height: 100%;
  border-radius: 15rem 0 15rem 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  position: relative;
  overflow: hidden;
}

.about-hero-section .about-distorted-section .ads-content img {
  width: 100%;
  height: 65rem;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 15rem 0 15rem 0;
}

.about-hero-section .about-distorted-section .ads-content .adsc-right-box {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.about-hero-section .about-distorted-section .ads-content .stats-grid {
  width: 100%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2.5rem;
}

.about-hero-section
  .about-distorted-section
  .ads-content
  .stats-grid
  .stats-grid-card {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.about-hero-section
  .about-distorted-section
  .ads-content
  .stats-grid
  .stats-grid-card
  h3 {
  font-size: 5rem;
  color: #323232;
  font-weight: 500;
}

.about-hero-section
  .about-distorted-section
  .ads-content
  .stats-grid
  .stats-grid-card
  h4 {
  font-size: 1.5rem;
  font-weight: 400;
  color: #323232;
}

.about-hero-section
  .about-distorted-section
  .ads-content
  .stats-grid
  .stats-grid-card
  h5 {
  font-size: 1.25rem;
  font-weight: 400;
  color: #949494;
}

.about-hero-section .about-distorted-section .ads-content .quote-box {
  width: 100%;
}

.about-hero-section .about-distorted-section .ads-content .quote-box h6 {
  font-size: 1.75rem;
  font-weight: 400;
  line-height: 2.5rem;
  margin: 0 0 1rem 0;
  color: #000;
}

.about-hero-section .about-distorted-section .ads-content .quote-box i {
  font-size: 1.5rem;
  width: 100%;
  color: #323232;
  text-align: right;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.about-hero-section .about-left-line-circle-box {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  position: absolute;
  left: 50%;
  bottom: 0;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  width: max-content;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
}

.about-hero-section .about-left-line-circle-box .about-left-line {
  width: 1px;
  height: 90vh;
}

.about-hero-section .about-left-line-circle-box .about-left-line div {
  width: 1px;
  height: 90vh;
  background-color: rgba(0, 0, 0, 0.5);
}

.about-hero-section .about-left-line-circle-box .about-left-circle {
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 0%);
  transform: translate(-50%, 0%);
  background-color: #fff;
  padding: 1rem;
}

.about-hero-section .about-left-line-circle-box .about-left-circle .dot {
  width: 0.5rem;
  height: 0.5rem;
  background-color: #323232;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 10;
}

.para-section {
  width: 100%;
  margin: 15rem 0;
}

.para-section .para-content {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 7.5rem 0 0 0;
}

.para-section .para-content p {
  width: 65%;
  font-size: 1.5rem;
  line-height: 2.5rem;
  color: #323232;
  text-align: center;
  margin: 0 0 2.5rem 0;
}

.para-section .para-content p span {
  color: green;
  font-weight: 600;
}

.tech-stack-section {
  width: 100%;
}

.tech-stack-section .tss-content {
  width: 75%;
  margin: 7.5rem auto 0 auto;
  padding: 5rem;
  background-color: #f5f6f7;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  grid-gap: 2.5rem;
  overflow: hidden;
}

.tech-stack-section .tss-content .tech-box {
  width: 100%;
}

.tech-stack-section .tss-content .tech-box h6 {
  font-size: 1.5rem;
  font-weight: 400;
  color: #323232;
  margin: 0 0 0.75rem 0;
}

.tech-stack-section .tss-content .tech-box .tech-bar {
  width: 100%;
  height: 0.75rem;
  background-color: rgba(0, 0, 0, 0.15);
}

.tech-stack-section .tss-content .tech-box .tech-bar .tech-bar-inner {
  position: relative;
  height: 0.75rem;
  background-color: rgba(0, 0, 0, 0.85);
}

.tech-stack-section .tss-content .tech-box .tech-bar .tech-bar-inner span {
  font-size: 1.35rem;
  position: absolute;
  top: 0;
  right: 0;
  color: #323232;
  -webkit-transform: translate(0, -125%);
  transform: translate(0, -125%);
}

.last-imp {
  width: 100%;
  text-align: center;
  margin: 15rem 0 0 0;
  font-size: 1.65rem;
  font-weight: 500;
  font-style: italic;
}

.tech-stack-grid-simple {
  width: 75%;
  margin: 7.5rem auto 0 auto;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  grid-gap: 3.5rem;
}

.tech-stack-grid-simple p {
  font-size: 1.5rem;
  width: 100%;
  color: #000;
  height: 3.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 1rem;
  position: relative;
  z-index: 2;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.tech-stack-grid-simple p img {
  width: 2.25rem;
  height: 2.25rem;
  -o-object-fit: contain;
  object-fit: contain;
  margin: 0 0.5rem 0 0;
}

.ts-grid-card-simple {
  width: 100%;
  position: relative;
  -webkit-transition: 0.75s;
  transition: 0.75s;
  /* &:hover {
    background-color: rgba(0, 0, 0, 0.035);
  } */
}

.ts-grid-card-simple .percentage-box {
  width: 0%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background-color: rgba(0, 0, 0, 0.1);
}

.ts-grid-card-simple:nth-child(1):hover .percentage-box {
  width: 90%;
}

.ts-grid-card-simple:nth-child(2):hover .percentage-box {
  width: 85%;
}

.ts-grid-card-simple:nth-child(3):hover .percentage-box {
  width: 90%;
}

.ts-grid-card-simple:nth-child(4):hover .percentage-box {
  width: 95%;
}

.ts-grid-card-simple:nth-child(5):hover .percentage-box {
  width: 85%;
}

.ts-grid-card-simple:nth-child(6):hover .percentage-box {
  width: 80%;
}

.ts-grid-card-simple:nth-child(7):hover .percentage-box {
  width: 75%;
}

.ts-grid-card-simple:nth-child(8):hover .percentage-box {
  width: 80%;
}

.ts-grid-card-simple:nth-child(9):hover .percentage-box {
  width: 85%;
}

.ts-grid-card-simple:nth-child(10):hover .percentage-box {
  width: 50%;
}

.ts-grid-card-simple:nth-child(11):hover .percentage-box {
  width: 75%;
}

.ts-grid-card-simple:nth-child(12):hover .percentage-box {
  width: 60%;
}

.ts-grid-card-simple:nth-child(13):hover .percentage-box {
  width: 35%;
}

.ts-grid-card-simple:nth-child(14):hover .percentage-box {
  width: 80%;
}

.ts-grid-card-simple:nth-child(15):hover .percentage-box {
  width: 85%;
}

.ts-grid-card-simple:nth-child(16):hover .percentage-box {
  width: 100%;
}

@media screen and (max-width: 1000px) {
  .tech-stack-grid-simple {
    width: 100%;
  }
  .para-section .para-content p {
    width: 100%;
  }
  .about-hero-section .about-distorted-section .ads-content {
    grid-gap: 5rem;
    -ms-grid-columns: 35rem 1fr;
    grid-template-columns: 35rem 1fr;
  }
  .about-hero-section .about-distorted-section .ads-content .adsc-left-box {
    display: none;
  }
  .tech-stack-grid-simple {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 750px) {
  .about-hero-section .about-distorted-section .ads-content {
    grid-gap: 3.5rem;
    -ms-grid-columns: 30rem 1fr;
    grid-template-columns: 30rem 1fr;
  }
  .about-hero-section .about-distorted-section .ads-content .stats-grid {
    grid-gap: 1.5rem;
  }
  .about-hero-section
    .about-distorted-section
    .ads-content
    .stats-grid
    .stats-grid-card
    h3 {
    font-size: 4rem;
  }
  .about-hero-section
    .about-distorted-section
    .ads-content
    .stats-grid
    .stats-grid-card
    h4 {
    font-size: 1.35rem;
  }
  .about-hero-section
    .about-distorted-section
    .ads-content
    .stats-grid
    .stats-grid-card
    h5 {
    font-size: 1.15rem;
  }
  .about-hero-section .about-distorted-section .ads-content img,
  .about-hero-section .about-distorted-section .ads-content .about-img-box {
    border-radius: 12.5rem 0 12.5rem 0;
  }
  .about-hero-section .about-distorted-section .ads-content img {
    height: 55rem;
  }
  .tech-stack-grid-simple p {
    font-size: 1.35rem;
  }
  .tech-stack-grid-simple {
    grid-gap: 1.5rem;
  }
}

@media screen and (max-width: 650px) {
  .about-hero-section .about-distorted-section .ads-content {
    grid-gap: 3.5rem;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    width: 65%;
    margin: 0 auto;
  }
  .about-hero-section .about-distorted-section .ads-content .about-img-box {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .about-hero-section .about-distorted-section .ads-content .stats-grid {
    margin: 2.5rem 0 5rem 0;
  }
  .tech-stack-grid-simple {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 550px) {
  .about-hero-section .about-distorted-section .ads-content {
    width: 75%;
  }
}

@media screen and (max-width: 450px) {
  .about-hero-section .about-distorted-section .ads-content {
    width: 100%;
  }
  .about-hero-section .about-distorted-section .ads-content .adsc-center-box {
    width: 85%;
    margin: 0 auto;
  }
  .about-hero-section .about-distorted-section .ads-content img,
  .about-hero-section .about-distorted-section .ads-content .about-img-box {
    border-radius: 10rem 0 10rem 0;
  }
}

@font-face {
  font-family: Nebula-Regular;
  src: url(../fonts/Nebula/Nebula-Regular.otf) format("WOFF");
  font-display: block;
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

:root {
  font-size: 10px;
}

html,
body {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
.stop_scroll{
  max-height: 80vh;
  overflow: hidden;
}
.box {
  width: 100%;
  height: 100%;
  max-width: 1500px;
  margin: 0 auto;
  padding: 0 10rem;
}

a {
  text-decoration: none;
  cursor: pointer;
}

button {
  cursor: pointer;
  border: 0;
  outline: 0;
}

.nebula-regular {
  font-family: Nebula-Regular, "Open Sans", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
}

.section-heading {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

.section-heading h3 {
  font-size: 2.5rem;
}

.section-heading h3 * {
  font-family: Nebula-Regular, "Open Sans", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
}

.section-heading h4 {
  font-size: 1.5rem;
  font-weight: 400;
  opacity: 0.85;
  margin: 0.75rem 0 0 0;
  width: 85%;
}

main {
  position: relative;
}

/* ==== logo ====== */
.logo {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 1rem 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  transform: rotate(-90deg);
}
.logo-main {
  height: 77px;
  width: 77px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo .logo-box {
  width: 2.75rem;
  height: 0.45rem;
  background-color: #000;
}
.logo .logo-box.white-bg,
.logo .logo-box.logo-white {
  /* background-color: #fff; */
  background-color: hsl(225, 60%, 96%);
}
.logo .logo-box.logo-white:nth-child(1)::after {
  background: hsl(225, 60%, 96%);
}



.logo .logo-box:nth-child(1) {
  -webkit-transform: rotate(-125deg);
  transform: rotate(-125deg);
  border-bottom-right-radius: 30px;
}
.logo .logo-box:nth-child(2) {
  -webkit-transform: rotate(55deg);
  transform: rotate(55deg);
  margin-left: -15px;
  margin-top: 2px;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

.logo .logo-box:nth-child(3) {
  -webkit-transform: rotate(-50deg);
  transform: rotate(-50deg);
  margin-left: -12px;
  margin-top: 3px;
  border-bottom-left-radius: 30px;
  border-top-right-radius: 19px;
}

.logo .logo-box:nth-child(4) {
  border-top-left-radius: 30px;
  margin-top: 2px;
  -webkit-transform: rotate(55deg);
  transform: rotate(55deg);
  margin-left: -11.7px;
  border-bottom-right-radius: 30px;
  position: relative;
}
.logo .logo-box:nth-child(1)::after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -6px;
  width: 5px;
  height: 100%;
  background-color: #000;
  content: "";
}

/* nav bars */
nav {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  position: absolute;
  bottom: 22.5rem;
  right: 0;
  -webkit-transform: translate(15rem, 0) rotate(-90deg);
  transform: translate(15rem, 0) rotate(-90deg);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

nav a {
  font-size: 1.35rem;
  color: #323232;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

nav a span {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  display: block;
  font-family: Nebula-Regular, "Open Sans", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
}

nav a:hover span {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}

nav i {
  width: 3px;
  height: 3px;
  background-color: #323232;
  border-radius: 50%;
  margin: 0 2.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.breadcrumb-section {
  margin: 20rem 0 5rem 0;
  width: 100%;
}

.breadcrumb-section .bcs-content {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.breadcrumb-section .bcs-content a {
  font-size: 1.5rem;
  color: #323232;
}

.breadcrumb-section .bcs-content span {
  font-size: 1.5rem;
  color: #323232;
  margin: 0 2rem;
}

.chs-heading-container {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 15rem 0 6.5rem 0;
  display: flex;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.chs-heading-container h1 {
  font-size: 7.5rem;
  position: relative;
  text-align: center;
  position: relative;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.chs-heading-container h1 .hero-heading-line-1 {
  width: 45%;
  height: 1px;
  background-color: #323232;
  position: absolute;
  top: 0;
  right: -10%;
}

.chs-heading-container h1 .hero-heading-line-2 {
  width: 25%;
  height: 1px;
  background-color: #323232;
  position: absolute;
  bottom: 0;
  left: 10%;
}

.chs-heading-container h5 {
  font-size: 1.5rem;
  font-weight: 400;
  margin: 2.5rem 0 0 0;
}

.circular-text {
  position: relative;
  border-radius: 100%;
  font-size: 1.25rem;
  font-weight: 400;
}

.circular-text span {
  position: absolute;
  -webkit-transform-origin: top left;
  transform-origin: top left;
}

.page-heading * {
  font-family: Nebula-Regular, "Open Sans", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
}

@media screen and (max-width: 1150px) {
  .box {
    padding: 0 5rem;
  }
  nav {
    bottom: 20rem;
  }
}

@media screen and (max-width: 850px) {
  .box {
    padding: 0 3.5rem;
  }
}

@media screen and (max-width: 850px) {
  .box {
    padding: 9px 2rem;
  }
}

@media screen and (max-width: 550px) {
  nav {
    display: none;
  }
  .circular-text {
    font-size: 1rem;
  }
  .chs-heading-container h1 {
    font-size: 4.25rem;
  }
  .breadcrumb-section {
    margin: 10rem 0 5rem 0;
  }
}

@media screen and (max-width: 768px) {
  .fixed-whts-btn {
    font-size: 26px;
    width: 50px;
    height: 50px;
    bottom: 8px;
  }
  .fixed-upwork-btn {
    bottom: 63px;
  }
}
@media screen and (max-width: 460px) {
  .hero-section .hero-section-content h1 {
    font-size: 4.3rem;
  }
}
@media screen and (max-width: 370px) {
  .hero-section .hero-section-content h1 {
    font-size: 3.9rem;
  }
}
@media screen and (max-width: 336px) {
  .hero-section .hero-section-content h1 {
    font-size: 3.2rem;
  }
}

.white-bg {
  background-color: white;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}


/*# sourceMappingURL=main.css.map */
